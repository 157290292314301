var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "payment-type payment-type-delivery",
      attrs: { "data-test": "payment-type-delivery" }
    },
    [
      _vm.paymentType.title
        ? _c("div", {
            staticClass: "payment-type-title",
            domProps: { innerHTML: _vm._s(_vm.paymentType.title) }
          })
        : _vm._e(),
      _c("div", {
        staticClass: "payment-type-description",
        staticStyle: { color: "var(--v-primary-base)" },
        domProps: { innerHTML: _vm._s(_vm.paymentType.descr) }
      }),
      _c(
        "div",
        { staticClass: "custom-warning" },
        [
          _c(
            "v-alert",
            {
              staticClass: "wrn",
              attrs: { type: "warning", title: "ATTENZIONE" }
            },
            [
              _vm._v(" ATTENZIONE"),
              _c("br"),
              _vm._v(" Per ordini superiori a "),
              _c("strong", [_vm._v(_vm._s(_vm.$n(75, "currency")))]),
              _vm._v(", all'importo totale aggiungere "),
              _c("strong", [_vm._v(_vm._s(_vm.$n(2, "currency")))]),
              _vm._v(" di marca da bollo. ")
            ]
          )
        ],
        1
      ),
      _vm.error
        ? _c(
            "v-alert",
            {
              attrs: {
                type: "error",
                border: "left",
                transition: "v-fab-transition"
              }
            },
            [_vm._v(" " + _vm._s(_vm.error) + " ")]
          )
        : _vm._e(),
      _vm.$te("paymentTypeList.redirectText." + _vm.paymentType.paymentTypeId)
        ? _c("div", {
            staticClass: "redirect-text",
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "paymentTypeList.redirectText." +
                    _vm.paymentType.paymentTypeId
                )
              )
            }
          })
        : _vm._e(),
      _c("ResponseMessage", { attrs: { response: _vm.response } }),
      _c(
        "v-row",
        { staticClass: "d-flex justify-end" },
        [
          _vm.mode != "order" && !_vm.options.hidePrevButton
            ? _c("v-col", {
                staticClass: "d-flex align-center",
                attrs: { cols: "12", md: "6" }
              })
            : _vm._e(),
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-end",
              attrs: { cols: "12", md: "6" }
            },
            [
              _vm.options.confirm.show
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mt-3 mt-sm-0 ml-2 gateway-btn",
                      class: _vm.options.confirm.class,
                      attrs: {
                        color: _vm.options.confirm.color,
                        outlined: _vm.options.confirm.outline,
                        depressed: _vm.options.confirm.depressed,
                        large: _vm.options.confirm.large
                      },
                      on: { click: _vm.buy }
                    },
                    [_vm._v(" " + _vm._s(_vm.options.confirm.label) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.$te(`paymentTypeList.${_vm.paymentType.gatewayClient}.caption`)
        ? _c("i18n", {
            staticClass: "caption",
            attrs: {
              path: `paymentTypeList.${_vm.paymentType.gatewayClient}.caption`,
              tag: "div"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }